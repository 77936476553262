import React from 'react';
import { TouchButton } from './TouchButton';

export const InterestedBox = () => {
  return (
    <div className="overflow-hidden min-w-[628px] min-h-[305px] relative mb-4">
      <div className="p-6 py-14 text-center">
        <h4 className="text-6xl font-bold text-eod-black mb-8">Interested<br/><span className="font-normal">in our treasures?</span></h4>
        <TouchButton />
      </div>
    </div>
  )
};