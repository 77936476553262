import React from "react";
import DOMPurify from 'dompurify';
import { Product, ProductImage } from "../../types";
import { SafeHtmlComponent } from "../../components/SafeHtmlComponent";

export interface ProductDetailProps {
  product: Product;
}

export default function ProductDetail({ product }: ProductDetailProps) {
  const [selectedImage, setSelectedImage] = React.useState<null | ProductImage>(null);
  return (
    <div className="bg-white">
      {selectedImage && (
        <div
          className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center ml-4 w-[calc(100%-2rem)]"
          onClick={() => setSelectedImage(null)}
        >
          <img
            src={selectedImage.big}
            alt={selectedImage.alt}
            className="max-h-[90%] max-w-[90%]"
          />
        </div>
      )}
      {(
        <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
          <div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {product.title}
            </h2>
            <h3 className="text-2xl tracking-tight text-gray-900 sm:text-2xl mt-5">
              Technical Specifications
            </h3>
            <p className="mt-4 text-gray-500">
            <SafeHtmlComponent htmlContent={product.description} />
            </p>

            {product.features && (
              <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                {product.features.map((feature, i) => (
                  <div key={i} className="border-t border-gray-200 pt-4">
                    <dt className="font-medium text-gray-900">
                      {feature.title}
                    </dt>
                    <dd className="mt-2 text-sm text-gray-500">
                      {feature.desc}
                    </dd>
                  </div>
                ))}
              </dl>
            )}
          </div>
          <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
            {product.images &&
              product.images.map((image, i) => (
                <img
                  src={image.small}
                  alt={image.alt}
                  className="rounded-lg bg-gray-100"
                  key={i}
                  onClick={() => setSelectedImage(image)}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

