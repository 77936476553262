import React from 'react';
import { ProductSquare } from './productSquare';
import { InterestedBox } from './InterestedBox';
import productsJSON from '../../products.json';

export const ProductList = () => {
  const products = productsJSON.map((product) => (
    <ProductSquare key={product.id} data={product} />
  ));
  return (
    <div id="products" className="max-w-site mt-14 font-barlow bg-product-list bg-no-repeat bg-right-bottom m-auto">
      <div className="flex">
        <h2 className="text-7xl font-bold text-left uppercase header-gradient pl-2">Products</h2>
      </div>
      <div className="flex flex-wrap p-2 mt-10 md:justify-between justify-center">
        {products}
        <InterestedBox />
      </div>
    </div>
  );
};
