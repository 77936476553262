import { Menu } from "./Menu";
import React from "react";

export const MainHeader = () => {
  return (
    <div className="bg-main bg-cover bg-center h-dvh w-full relative">
        <div className="bg-main-pattern bg-center bg-repeat h-full w-full">
          <Menu />
          <h1 className="font-barlow font-bold text-white text-center pt-20 text-[11dvw] 2xl:text-[10rem] leading-[9dvw] 2xl:leading-[8rem]">
            OUR GOAL IS{" "}
            <div className="ml-[15dvw]">
              YOUR <span className="text-brand">SAFETY</span>
            </div>
          </h1>
          <div className="flex flex-col gap-10 items-center lg:mt-0 mt-10">
            <div
              className="2xl:hover:border-brand border-transparent border-dashed border border-b-0 box-border transition duration-300 group
    
    lg:right-[30dvw] lg:bottom-[18dvw] lg:h-[0dvw] lg:w-[60dvw] lg:absolute
    2xl:right-[20rem] 2xl:bottom-[18rem] 2xl:h-[5rem] 2xl:w-[70dvw] 2xl:border-r-0
    "
            >
              <div className="font-barlow lg:absolute -bottom-[140px] -left-[19px]  w-[150px] ">
                <div className="w-[40px] font-bold text-white group-hover:text-brand text-[40px] border-4 group-hover:border-brand border-r-0 border-b-0 border-l-0">
                  01
                </div>
                <div className="text-white text-[32px] leading-[32px] text-left">
                  Maximal
                  <br />
                  Compactness
                </div>
              </div>
            </div>
            <div
              className="2xl:hover:border-brand border-transparent border-dashed border border-b-0  transition duration-300 group
    
    lg:right-[30dvw] lg:bottom-[18dvw] lg:h-[15dvw] lg:w-[40dvw] lg:absolute
    2xl:right-[20rem] 2xl:bottom-[18rem] 2xl:h-[5rem] 2xl:w-[50dvw] 2xl:border-r-0
    "
            >
              <div className="font-barlow lg:absolute -bottom-[140px] -left-[19px]  w-[150px] ">
                <div
                  className="w-[40px] font-bold text-white group-hover:text-brand text-[40px]
        border-4 group-hover:border-brand border-r-0 border-b-0 border-l-0"
                >
                  02
                </div>
                <div className="text-white text-[32px] leading-[32px] text-left">
                  Minimal
                  <br />
                  Weight
                </div>
              </div>
            </div>
            <div
              className="2xl:hover:border-brand border-transparent border-dashed border border-b-0 transition duration-300 group
  
    lg:right-[30dvw] lg:bottom-[18dvw] lg:h-[0dvw] lg:w-[22dvw] lg:absolute
    2xl:right-[20rem] 2xl:bottom-[18rem] 2xl:h-[5rem] 2xl:w-[30dvw] 2xl:border-r-0
    "
            >
              <div className="font-barlow lg:absolute -bottom-[140px] -left-[19px] w-[150px] ">
                <div className="w-[40px] font-bold text-white group-hover:text-brand text-[40px] border-4 group-hover:border-brand border-r-0 border-b-0 border-l-0">
                  03
                </div>
                <div className="text-white text-[32px] leading-[32px] text-left">
                  Maximal
                  <br />
                  Durability
                </div>
              </div>
            </div>
          </div>
          <div className="bg-main-coil bg-cover absolute right-0 size-[45dvw] -bottom-[10dvw] 2xl:size-[47rem] 2xl:-bottom-[11rem] "></div>
        </div>
      </div>
  );
};
