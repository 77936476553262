import React, { useEffect } from 'react';
import ProductDetail from './ProductDetail';
import { Product } from '../../types';

import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { SafeHtmlComponent } from '../../components/SafeHtmlComponent';

interface ProductSquareProps {
  data: Product;
}

const blank = 'images/blank.png';

export const ProductSquare = ({data}: ProductSquareProps) => {
  const [open, setOpen] = useState(false)

  const [imageSrc, setImageSrc] = useState(blank);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = data.image;

    img.onload = () => setImageSrc(data.image);
    img.onerror = () => {
      setIsValid(false);
      setImageSrc(blank);
    };
  }, [data.image]);
  


  return (
    <>
    
      <div className="bg-white border overflow-hidden w-[305px] group relative mb-4 cursor-pointer" onClick={() => setOpen(true)}>
        <div className="p-4">
          <div className="w-6 bg-brand h-1"></div>
          <h3 className="text-2xl font-medium text-eod-black text-left">{data.title}</h3>

          {isValid ? (
            <img src={imageSrc} alt={data.title} className="w-full h-56 object-none" />
          ) : (
            <div className="w-full h-56 items-center p-8 text-center text-xl text-gray-400 align-middle flex justify-center">
              <p>
                Image not available.<br/>We are working on it.
              </p>
            </div>
          )}

        </div>
        <div className="hidden group-hover:block absolute w-full h-full bg-brand top-0 left-0 transition-all duration-500 p-4">
          <div className="w-6 bg-brand h-1"></div>
          <h4 className='text-2xl font-medium text-eod-black text-left mb-4'>{data.title}</h4>
          <div className="text-gray-700 text-left"><SafeHtmlComponent htmlContent={data.perex} /></div>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                  <div
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8 cursor-pointer"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </div>

                  <ProductDetail product={data} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </>
  )
};