import "./App.css";
import {EodForm} from './pages/home/EodForm';
import { Footer } from "./components/Footer";
import { MainHeader } from "./components/MainHeader";
import { ProductList } from "./pages/home/ProducList";
import { AboutUs } from "./pages/home/AboutUs";

function App() {
  return (
    <div className="App">
      <MainHeader />
      <ProductList />
      <AboutUs />
      <EodForm />
      <Footer />
    </div>
  );
}

export default App;
