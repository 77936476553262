import React from 'react';

export const Menu = () => {
  return (
    <div className=" items-center h-[85px] bg-menu/50 text-white font-barlow text-xl w-full">
      <div className="max-w-site px-10 flex space-x-0 py-1.5 m-auto">
        <div className=" basis-1/4 flex flex-col justify-center">
          <img className="max-w-[109px] max-h-[76px]" src="images/logo_main_menu.png" alt="CZEOD logo"/>
        </div>
        <div className="flex gap-4 justify-right basis-3/4 flex-row-reverse py-6">
          <a href="#contact" className="hover:text-gray-300">Contact</a>
          <a href="#about-us" className="hover:text-gray-300">Who we are</a>
          <a href="#products" className="hover:text-gray-300">Products</a>
        </div>
      </div>
    </div>
  );
};
