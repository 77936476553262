import React from 'react';

export const AboutUs = () => {
  return (
    <div id="about-us" className="mt-20 font-barlow bg-about-us bg-no-repeat bg-right" style={{backgroundColor: "#2F211E"}}>
      <div className=" m-auto max-w-site py-20 flex">
        <div className="md:w-5/12 w-full">
          <div className="flex">
            <h2 className="mx-20 text-7xl font-bold text-left uppercase about-us-gradient pl-2">About <span className="text-white">us</span></h2>
          </div>
          <div className="text-white text-lg mt-10 mx-20 text-left">
              We are a family-owned company specializing in the production of tools and equipment for EOD operators, technicians, sappers, and breachers. Our goal is simplicity, minimalism, and functionality of each tool. We prioritize the maximum safety of individual operators and strive to combine it with maximum compactness and minimal weight. We understand that in war zones there are tens to thousands of mines per square kilometer, so we realize that many types of tools are consumable, and during demining, operators must have an adequate supply. Therefore, we strive to ensure the lowest possible price.
              <img src="images/sign.png" alt="sign CZEOD" className="mt-14 max-w-[320px]" />
        </div>
        </div>
        <div className="md:w-7/12 relative hidden md:block">
          <img src="images/about_us_1.jpg" alt="about us - action" className="absolute right-[80px] top-[200px]" />
          <img src="images/about_us_2.jpg" alt="about us - dig" className="absolute top-[-120px] right-0" />
          <img src="images/about_us_3.jpg" alt="about us - coil" className="absolute bottom-[-140px] left-[-60px]" />
          <img src="images/about_us_4.jpg" alt="about us - hooks" className="absolute top-[80px] left-[40px]" />
          <img src="images/polygon.png" alt="polygon background" className="absolute top-[80px] left-[247px]" />
          <img src="images/logo_small.png" alt="logo small" className="absolute top-[120px] left-[322px]" />
        </div>
      </div>
    </div>
  );
};