import React from "react";
import { postData } from "../../fetch";

function ValidateEmail(input: string) {
  var emailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
  return emailRegex.test(input);
}

export const EodForm = () => {
  const [name, setName] = React.useState("");
  const [fakeValue, setFakeValue] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [isSending, setIsSending] = React.useState(false);
  const [isValid, setIsValid] = React.useState({ name: false, email: false, message: false });
  const [isDirty, setIsDirty] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    const fieldName = event.target.name;
    switch (fieldName) {
      case "name":
        setName(value);
        break;
      case "secondName":
        setFakeValue(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "message":
        setMessage(value);
        break;
    }
    setIsValid({ ...isValid, [fieldName]: value !== "" && (fieldName === "email" ? ValidateEmail(value) : true)});
  };
  const handleSubmit = () => {
    setIsDirty(true);
    if (fakeValue === "" && name !== "" && email !== "" && message !== "") {
      setIsSending(true);
      setIsError(false);
      setIsSuccess(false);
      postData("/send-message", { name, email, message }).then((data) => {
        setIsSending(false);
        setIsDirty(false);
        console.log(data); // JSON data parsed by `data.json()` call
        if (data.message === "Message sent successfully") {
          setName("");
          setEmail("");
          setMessage("");
          setIsSuccess(true);
        } else {
          setIsError(true);
        }
      }).catch((error) => {
        setIsSending(false);
        setIsError(true);
      })
    }
  };
  console.log(isValid);

  return (
    <div id="contact" className="px-4 py-10 sm:px-6 sm:py-28 lg:px-8 xl:px-28 xl:py-32">
      <div className="py-12">
        <h2 className="text-2xl font-bold mb-20" id="contact_form">Contact form</h2>
        <div className="mt-8 max-w-md mx-auto">
          <div className="grid grid-cols-1 gap-6">
            <label className="block text-left">
              <span className="text-gray-700">Full name</span>
              <input
                type="text"
                name="name"
                className="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                placeholder=""
                value={name} onChange={handleChange}
              />
              {!isValid?.name && !isSending && isDirty && <span className="text-error">Full name is required</span>}
              <input
                type="text"
                name="secondName"
                style={{ display: "none" }}
                placeholder=""
                value={fakeValue} onChange={handleChange}
              />
            </label>
            <label className="block text-left">
              <span className="text-gray-700">Email address</span>
              <input
                type="email"
                name="email"
                className="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                placeholder="john@example.com"
                value={email} onChange={handleChange}
              />
              {!isValid?.email && !isSending && isDirty && <span className="text-error">Valid email is required</span>}
            </label>
            <label className="block text-left">
              <span className="text-gray-700">Your message</span>
              <textarea
                name="message"
                className="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                rows={2}
                value={message} onChange={handleChange}
              ></textarea>
              {!isValid?.message && !isSending && isDirty && <span className="text-error">Message is required</span>}
            </label>
           
            <div className="block">
              <button
                onClick={handleSubmit}
                className="py-4 px-14 bg-brand text-eod-black font-bold text-lg outline -outline-offset-6"
              >
                {isSending && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>}
                Send{isSending && "ing..."}
              </button>
              {isSuccess && <div className="bg-brand mt-4 p-2">Message was sent successfully</div>}
              {isError && <div className="bg-error mt-4 p-2 text-white">Something went wrong, please contact us via email</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
