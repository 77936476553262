import DOMPurify from "dompurify";

export const SafeHtmlComponent = ({ htmlContent }: {htmlContent: string}) => {
  // Sanitize the HTML content
  const sanitizedHtml = DOMPurify.sanitize(htmlContent, {
    ALLOWED_TAGS: ['br'], // Only allow <br/> tags
  });

  // Render the sanitized HTML content
  return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};